/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, "Estoy probando Dropbox, uno de estos servicios en la nube que se estan poniendo de moda ultimamente, en este caso una carpeta online para compartir archivos con tus otros ordenadores, tus amigos o con quien tu quieras. Por ahora estoy encantado, el unico problema que he tenido es que el paquete para instalar predeterminado es para el nautilus de gnome, y yo uso KDE y XFCE, pero he seguido el siguiente tutorial y me ha ido de perlas. ", React.createElement(_components.a, {
    href: "http://fedoreando.wordpress.com/2009/01/01/dropbox-en-kde/"
  }, "http://fedoreando.wordpress.com/2009/01/01/dropbox-en-kde/"), " Un saludo");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
